<template>
  <v-container class="content-page">
    <title-header-action
      :hasActionPermission="canRegisterPartner"
      btnLabel="Cadastrar Parceiro e PEV"
      :btnAction="goToAddPartner"
    />

    <div class="mb-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
          :key="`card-info-${index}`"
          v-for="(cardInfo, index) in cardsInfo"
        >
          <card-info :title="cardInfo.title" :value="cardInfo.value" />
        </v-col>
      </v-row>
    </div>

    <v-card class="table-page pt-7">
      <div class="table-page d-flex flex-column justify-space-between">
        <div>
          <!--filters -->
          <template v-if="showFilters">
            <div class="row px-5 d-flex justify-end">
              <v-col cols="12" md="3" v-if="userIsAdmin">
                <v-select
                  :items="crcs"
                  item-text="description"
                  item-value="id"
                  outlined
                  @change="setFilter('crcId', $event)"
                  color="secondary-color-1"
                  label="Todos CRCs"
                ></v-select>
              </v-col>

              <v-col cols="9" sm="8" md="3">
                <v-text-field
                  @input="setFilterDebounced('companyName', $event)"
                  solo
                  flat
                  full-width
                  color="secondary-color-1"
                  background-color="white-1"
                  placeholder="Buscar Parceiro"
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>

              <v-col cols="3" sm="4" md="2">
                <report-options
                  fileName="Parceiros"
                  :header="headerReport"
                  :body="partners"
                />
              </v-col>
            </div>

            <v-divider></v-divider>
          </template>

          <template v-if="!partners.length">
            <div class="pt-16">
              <data-not-found
                v-if="!filterPristine"
                title="Parceiro não encontrado."
                :imgSrc="require('@/assets/partner.png')"
              />

              <data-not-found
                v-if="filterPristine"
                title="Você ainda não cadastrou nenhum parceiro, deseja cadastrar?"
                btnLabel="Cadastrar o primeiro parceiro"
                :btnAction="goToAddPartner"
                :imgSrc="require('@/assets/partner.png')"
              />
            </div>
          </template>

          <template v-if="partners.length">
            <v-data-table
              :headers="headers"
              :items="partners"
              :items-per-page="tableInfo.itemsPerPage"
              hide-default-footer
            >
              <!-- Situation -->-->
              <template v-slot:[`item.situation`]="{ item }">
                <partner-situation-chip
                  v-if="item.situation"
                  :text="item.situation"
                />
              </template>

              <!--Options -->
              <template v-slot:[`item.id`]="{ item }">
                <partner-menu-item
                  v-if="canDetailPartner"
                  :id="item.id"
                  :canDetailPartner="canDetailPartner"
                />
              </template>
            </v-data-table>
          </template>
        </div>

        <div v-if="partners.length">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  import { format as formatCnpj } from "@fnando/cnpj";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import CardInfo from "@/modules/partner/components/CardInfo";
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import Pagination from "@/modules/core/views/components/Pagination";
  import DataNotFound from "@/modules/core/views/components/DataNotFound";
  import ReportOptions from "@/modules/core/views/components/ReportOptions";
  import PartnerMenuItem from "@/modules/partner/components/PartnerMenuItem";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";
  import PartnerSituationChip from "@/modules/partner/views/components/PartnerSituationChip";

  export default {
    name: "Partners",

    components: {
      TitleHeaderAction,
      DataNotFound,
      ReportOptions,
      Pagination,
      PartnerMenuItem,
      CardInfo,
      PartnerSituationChip
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      headers: [
        {
          text: "Empresa",
          align: "start",
          value: "companyName"
        },
        { text: "CNPJ", value: "cnpj" },
        { text: "Responsável", value: "agentName" },
        { text: "Telefone", value: "phone" },
        { text: "E-mail", value: "email" },
        { text: "Situação", value: "situation" },
        { value: "id", sortable: false }
      ],

      headerReport: [
        {
          header: "Empresa",
          dataKey: "companyName"
        },
        { header: "CNPJ", dataKey: "cnpj" },
        { header: "Responsável", dataKey: "agentName" },
        { header: "Telefone", dataKey: "phone" },
        { header: "E-mail", dataKey: "email" },
        { header: "Situação", dataKey: "situation" }
      ],

      partners: [],

      crcs: [],

      pevsInfo: {
        countActivatedPevs: 0,
        countAllPevs: 0,
        countDisabledPevs: 0
      }
    }),

    created() {
      this.initFilter("crcId", null);
      this.initFilter("companyName", "");
    },
    mounted() {
      if (this.userIsAdmin) {
        this.fetchCrcs();
      }
      this.fecthPartners();
    },

    computed: {
      ...mapGetters({
        userIsAdmin: "user/getUserIsAdmin",
        user: "user/getUser",
        routes: "getRoutes"
      }),

      canRegisterPartner() {
        return this.routes?.some(
          route =>
            route.name === "addPEV" &&
            route?.meta?.permissions.includes(this.user.role.id)
        );
      },

      canDetailPartner() {
        return this.routes?.some(
          route =>
            route.name === "DetailPartner" &&
            route?.meta?.permissions.includes(this.user.role.id)
        );
      },

      showFilters() {
        if (this.partners.length) {
          return true;
        }
        return !this.partners.length && !this.filterPristine;
      },

      cardsInfo() {
        return [
          {
            title: "Total PEVs Cadastrados",
            value: this.pevsInfo.countAllPevs
          },
          {
            title: "Total PEVs Ativos",
            value: this.pevsInfo.countActivatedPevs
          },
          {
            title: "Total PEVs Inativos",
            value: this.pevsInfo.countDisabledPevs
          }
        ];
      }
    },

    methods: {
      ...mapActions("partner", ["getPartners"]),
      ...mapActions("crc", ["getCRCs"]),
      ...mapActions(["toggleSnackbar"]),

      async fecthPartners() {
        try {
          const filters = this.getPayloadData();
          const { data, meta, otherData } = await this.getPartners(filters);
          this.partners = this.formatPartnersData(data);

          this.setTableInfo(meta);
          this.pevsInfo = { ...otherData };
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatPartnersData(partners) {
        return partners.map(partner => {
          const phone = partner.agent.phones.length
            ? this.parsePhone(partner.agent.phones[0])
            : "não informado";

          return {
            id: partner.id,
            companyName: partner.companyName ?? "não informado",
            cnpj: partner.cnpj ? formatCnpj(partner.cnpj) : "não informado",
            agentName: partner.agent.name ?? "não informado",
            phone,
            email: partner.agent.email ?? "não informado",
            situation: partner.situation
          };
        });
      },

      async fetchCrcs() {
        try {
          const { data } = await this.getCRCs();
          this.crcs = [
            {
              description: "Todos",
              id: ""
            },
            ...data
          ];
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      goToAddPartner() {
        this.$router.push({ name: "addPartner" });
      },

      updateData() {
        this.fecthPartners();
      }
    }
  };
</script>

<style lang="scss" scoped></style>
