<template>
  <div>
    <v-card height="185">
      <div class="fill-height d-flex flex-column justify-center align-center">
        <h1 class="card-info-title">{{ title }}</h1>
        <span class="big-number">{{ value }}</span>
      </div>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "CardInfo",

    props: {
      title: {
        type: String,
        required: true
      },
      value: {
        type: Number,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  .card-info-title {
    font-size: 34px;
    color: $black-3;
  }
  .big-number {
    font-size: 48px;
    color: $black-1;
  }
</style>
