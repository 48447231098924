<template>
  <v-menu transition="slide-x-transition" bottom right>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        mdi-dots-horizontal
      </v-icon>
    </template>

    <v-list>
      <v-list-item
        v-if="canDetailPartner"
        :to="{ name: 'DetailPartner', params: { id } }"
        class="d-flex align-center item-dropdown-user"
      >
        <v-list-item-title>
          <v-icon class="item-dropdown-icon">mdi-store</v-icon
          ><span class="pl-3">Ver perfil</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    name: "PartnerMenuItem",

    props: {
      id: {
        type: Number,
        required: true
      },
      canDetailPartner: {
        type: Boolean,
        required: true
      }
    }
  };
</script>

<style lang="scss" scoped></style>
